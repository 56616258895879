exports.components = {
  "component---src-pages-with-locale-404-js": () => import("./../../../src/pagesWithLocale/404.js" /* webpackChunkName: "component---src-pages-with-locale-404-js" */),
  "component---src-pages-with-locale-about-us-js": () => import("./../../../src/pagesWithLocale/about-us.js" /* webpackChunkName: "component---src-pages-with-locale-about-us-js" */),
  "component---src-pages-with-locale-account-js": () => import("./../../../src/pagesWithLocale/account.js" /* webpackChunkName: "component---src-pages-with-locale-account-js" */),
  "component---src-pages-with-locale-cart-js": () => import("./../../../src/pagesWithLocale/cart.js" /* webpackChunkName: "component---src-pages-with-locale-cart-js" */),
  "component---src-pages-with-locale-contact-js": () => import("./../../../src/pagesWithLocale/contact.js" /* webpackChunkName: "component---src-pages-with-locale-contact-js" */),
  "component---src-pages-with-locale-free-sample-js": () => import("./../../../src/pagesWithLocale/free-sample.js" /* webpackChunkName: "component---src-pages-with-locale-free-sample-js" */),
  "component---src-pages-with-locale-index-js": () => import("./../../../src/pagesWithLocale/index.js" /* webpackChunkName: "component---src-pages-with-locale-index-js" */),
  "component---src-pages-with-locale-password-reset-js": () => import("./../../../src/pagesWithLocale/password-reset.js" /* webpackChunkName: "component---src-pages-with-locale-password-reset-js" */),
  "component---src-pages-with-locale-privacy-js": () => import("./../../../src/pagesWithLocale/privacy.js" /* webpackChunkName: "component---src-pages-with-locale-privacy-js" */),
  "component---src-pages-with-locale-products-adhesive-js": () => import("./../../../src/pagesWithLocale/products/adhesive.js" /* webpackChunkName: "component---src-pages-with-locale-products-adhesive-js" */),
  "component---src-pages-with-locale-products-applicator-wand-js": () => import("./../../../src/pagesWithLocale/products/applicator-wand.js" /* webpackChunkName: "component---src-pages-with-locale-products-applicator-wand-js" */),
  "component---src-pages-with-locale-products-book-js": () => import("./../../../src/pagesWithLocale/products/book.js" /* webpackChunkName: "component---src-pages-with-locale-products-book-js" */),
  "component---src-pages-with-locale-products-disposable-containers-js": () => import("./../../../src/pagesWithLocale/products/disposable-containers.js" /* webpackChunkName: "component---src-pages-with-locale-products-disposable-containers-js" */),
  "component---src-pages-with-locale-products-js": () => import("./../../../src/pagesWithLocale/products.js" /* webpackChunkName: "component---src-pages-with-locale-products-js" */),
  "component---src-pages-with-locale-products-lotion-set-js": () => import("./../../../src/pagesWithLocale/products/lotion-set.js" /* webpackChunkName: "component---src-pages-with-locale-products-lotion-set-js" */),
  "component---src-pages-with-locale-products-mascara-brush-js": () => import("./../../../src/pagesWithLocale/products/mascara-brush.js" /* webpackChunkName: "component---src-pages-with-locale-products-mascara-brush-js" */),
  "component---src-pages-with-locale-products-neutralizing-lotion-js": () => import("./../../../src/pagesWithLocale/products/neutralizing-lotion.js" /* webpackChunkName: "component---src-pages-with-locale-products-neutralizing-lotion-js" */),
  "component---src-pages-with-locale-products-pads-js": () => import("./../../../src/pagesWithLocale/products/pads.js" /* webpackChunkName: "component---src-pages-with-locale-products-pads-js" */),
  "component---src-pages-with-locale-products-perming-lotion-js": () => import("./../../../src/pagesWithLocale/products/perming-lotion.js" /* webpackChunkName: "component---src-pages-with-locale-products-perming-lotion-js" */),
  "component---src-pages-with-locale-products-silicone-curler-b-js": () => import("./../../../src/pagesWithLocale/products/silicone-curler-b.js" /* webpackChunkName: "component---src-pages-with-locale-products-silicone-curler-b-js" */),
  "component---src-pages-with-locale-products-silicone-curler-js": () => import("./../../../src/pagesWithLocale/products/silicone-curler.js" /* webpackChunkName: "component---src-pages-with-locale-products-silicone-curler-js" */),
  "component---src-pages-with-locale-products-start-kit-js": () => import("./../../../src/pagesWithLocale/products/start-kit.js" /* webpackChunkName: "component---src-pages-with-locale-products-start-kit-js" */),
  "component---src-pages-with-locale-products-tape-js": () => import("./../../../src/pagesWithLocale/products/tape.js" /* webpackChunkName: "component---src-pages-with-locale-products-tape-js" */),
  "component---src-pages-with-locale-products-tool-js": () => import("./../../../src/pagesWithLocale/products/tool.js" /* webpackChunkName: "component---src-pages-with-locale-products-tool-js" */),
  "component---src-pages-with-locale-sign-in-js": () => import("./../../../src/pagesWithLocale/sign-in.js" /* webpackChunkName: "component---src-pages-with-locale-sign-in-js" */),
  "component---src-pages-with-locale-sign-up-js": () => import("./../../../src/pagesWithLocale/sign-up.js" /* webpackChunkName: "component---src-pages-with-locale-sign-up-js" */),
  "component---src-pages-with-locale-success-js": () => import("./../../../src/pagesWithLocale/success.js" /* webpackChunkName: "component---src-pages-with-locale-success-js" */),
  "component---src-pages-with-locale-tos-js": () => import("./../../../src/pagesWithLocale/tos.js" /* webpackChunkName: "component---src-pages-with-locale-tos-js" */),
  "component---src-pages-with-locale-videos-js": () => import("./../../../src/pagesWithLocale/videos.js" /* webpackChunkName: "component---src-pages-with-locale-videos-js" */)
}

